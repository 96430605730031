<template>
    <div class="main-wrap">
        <el-table :data="cyberList"
                  class="customTable"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="name" label="考试内容"></el-table-column>
            <el-table-column label="学生提交答案" align="center">
                <template slot-scope="scope">
                    <div class="link-box">
                        <el-link type="primary" :underline="false"
                                 @click="viewRow(scope.row)"
                                 class="view-detail">查看详情
                        </el-link>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="score" :label="'得分（'+total+'分）'" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.score}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="link-box">
                        <el-link type="primary" :underline="false" @click="scoreDetail(scope.row)"
                                 class="view-detail">评分详情
                        </el-link>
                    </div>
                </template>
            </el-table-column> -->
        </el-table>
        <!-- <el-dialog title="评分标准"
                   :visible.sync="gradingDialog" width="830px" @close="handleCloseGradingDialog"
                   custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="gradingList" class="customTable goods-text-table" style="width: 100%; flex: 1;"
                          height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                          :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
                    <el-table-column prop="tea_name" label="评委名称" align="center"></el-table-column>
                    <el-table-column prop="score" label="分数" align="center"></el-table-column>
                    <el-table-column prop="time" label="评分时间" align="center"></el-table-column>
                </el-table>
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
    import {stuKefuExamDetail} from '@/utils/apis'

    export default {
        name: "CyberCustomer",
        data() {
            return {
                cyberList: [],
                gradingList: [],
                total: '',
                examId: this.$route.query.examId,
                studentId: this.$route.query.id,
                gradingDialog: false,
            }
        },
        created() {
            this.getCyberList();
        },
        methods: {
            getCyberList() {
                let params = {
                    exam_id: this.examId,
                    stu_id: this.studentId
                }
                stuKefuExamDetail(params).then(res => {
                    console.log(res.data,'33333')
                    this.cyberList.push({
                        name:res.data.name,
                        content:res.data.content,
                        exam_module_id:res.data.exam_module_id,
                        title:res.data.title,
                    })
                    // this.cyberList = [];
                    // this.total = res.data.score
                    // let obj = {
                    //     module: res.data.module,
                    //     score: res.data.score
                    // }
                    // this.cyberList.push(obj)
                    // this.gradingList = res.data.correct_detail.teacher
                })
            },
            viewRow(row) {
                // console.log(row)
                this.$router.push({
                    path: '/judges/jdTheerCyberCustomerDetail',
                    query: {
                        name:row.name,
                        content:row.content,
                        exam_module_id:row.exam_module_id,
                        title:row.title,
                    }
                })
            },
            handleCloseGradingDialog() {
                this.gradingDialog = false;
            },
            scoreDetail(row){
                this.gradingDialog = true
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrap {
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
</style>